import {createApp} from "vue";
import {i18nVue} from 'laravel-vue-i18n';
import AddToCart from "./components/cart/add_to_cart.vue";
import AddToCartCard from "./components/cart/add_to_cart_card.vue";
import CartNav from "./components/cart/nav_icon.vue";
import CartItem from "./components/cart/main.vue";
import NotificationNav from "./components/nav_notification.vue";

const app =
createApp({
  components: {
    "add-to-cart"     : AddToCart,
    "add-to-cart-card": AddToCartCard,
    "cart-nav"        : CartNav,
    "cart-item"       : CartItem,
    "noti-nav"        : NotificationNav,
  },
})
.use(i18nVue
  , {resolve: lang => import (`../lang/${lang}.json`)}
)
.mount("#app");


window.root = app;
